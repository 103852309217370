<template>
    <div>
        <table-catalog-stats/>
    </div>
</template>

<script>
import TableCatalogStats from '@/components/catalog/stats/table.vue'
export default {
  components: { TableCatalogStats },
    data () {
        return {
           
        }
    }
    
}
</script>